::-webkit-scrollbar {
  width: 0.4em;
  height: 0.4em;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}
::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
}

.loader {
  background: url('../../assets/loader.gif') no-repeat center center;
  position: absolute;
  top: calc(50vh - 25px);
  left: calc(50vw - 25px);
  height: 50px;
  width: 50px;
  z-index: 1;
  background-size: contain;
}

.accountHistoryloader {
  background: url('../../assets/loader.gif') no-repeat center center;
  position: absolute;
  height: 50px;
  width: 50px;
  z-index: 1;
  background-size: contain;
  margin-top: 50px;
}

.dataSimTable {
  max-height: calc(100vh - 260px);
  overflow-y: auto;
}
.dataSimTable::-webkit-scrollbar-track {
  margin-top: 60px;
}
.low {
  color: #d32f2f !important;
  background-color: #feebee;
  line-height: 17px;
  text-align: center !important;
  position: relative;
}
.medium {
  color: #f67c01 !important;
  background-color: #fff3e0;
  line-height: 17px;
  text-align: center !important;
  position: relative;
}
.high {
  color: #09bc71 !important;
  background-color: #e7f8f1;
  line-height: 17px;
  text-align: center !important;
  position: relative;
}

.doesNotExist {
  background-color: #f5f5f5 !important;
  text-align: center !important;
}
.arrowClass {
  width: 10px !important;
  height: 10px !important;
}
.increasedDelta {
  color: #09BC71;
}
.decreasedDelta {
  color: #D32F2F;
}
.tableCellTruncated {
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-bottom: 1px !important;
  max-width: 109px !important;
  overflow-wrap: break-word;
}
#simpleViewTable th:nth-child(1) {
  position: sticky;
  position: -webkit-sticky;
  background: #fff;
  left: 0;
  z-index: 20;
}
#simpleViewTable th:nth-child(2) {
  position: sticky;
  position: -webkit-sticky;
  background: #fff;
  left: 24px;
  z-index: 20;
}
#simpleViewTable td:nth-child(1) {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  z-index: 10;
  background: inherit
}
#simpleViewTable td:nth-child(2) {
  position: sticky;
  position: -webkit-sticky;
  left: 24px;
  z-index: 10;
  background: inherit
}
#simpleViewTable th:nth-last-child(1) {
  position: sticky;
  position: -webkit-sticky;
  background: #fff;
  right: 0;
  z-index: 20;
}
#simpleViewTable th:nth-last-child(2) {
  position: sticky;
  position: -webkit-sticky;
  background: #fff;
  right: 24px;
  z-index: 20;
}
#simpleViewTable td:nth-last-child(1) {
  position: sticky;
  position: -webkit-sticky;
  right: 0;
  z-index: 10;
  background: inherit
}
#simpleViewTable td:nth-last-child(2) {
  position: sticky;
  position: -webkit-sticky;
  right: 24px;
  z-index: 10;
  background: inherit
}

.medium .overlay, .high .overlay, .low .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  background-color: transparent;
}

.overlayIcon {
  position: absolute;
}
.overlayButton {
  position: relative;
    float: right;
}

.medium:hover .overlay, .high:hover .overlay, .low:hover .overlay{
  opacity: 0.5;
}

.customTooltipWidth {
  max-width: 100px;
}