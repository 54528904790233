.processingLoader {
  background: url('../../../assets/processing-loader.gif') no-repeat center center;
  height: 24px;
  width: 24px;
  z-index: 1;
  background-size: contain;
}

.hideScroll {
  height: calc(100vh - 75px);
  margin-top: 64px;
  overflow: scroll;
}

.dataCardContainer {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  box-shadow: none !important;
  width: 324px;
  height: 300px;
}
.dataCardContainer .cardContentBtn {
  display: none;
}
.dataCardContainer:hover .cardContentBtn {
  display: flex;
}
.dataCardContainer .cardConnectorIcon {
  display: flex;
}
.dataCardContainer:hover .cardConnectorIcon {
  display: none;
}

.dataCardContainer .hoverCard {
  margin-top: 100px;
}
.dataCardContainer:hover .hoverCard {
  margin-top: 75px;
}
.media {
  width: 80px;
  height: 56px;
  background-size: contain !important;
}
.mediaExport {
  width: 150px;
  height: 120px;
}
.media,
.mediaExport {
  text-align: center;
}

.cardContentIcon {
  padding: 0px 0px 15px 0px !important;
}
.cardContentTitle {
  padding: 0px !important;
}

.cardContainer {
  padding-top: 120px;
  padding-bottom: 40px;
  width: 420px;
}
