
.loader {
    background: url('../../../assets/loader.gif') no-repeat center center;
    position: absolute;
    top: calc(50vh - 25px);
    left: calc(50vw - 25px);
    height: 50px;
    width: 50px;
    z-index: 1;
    background-size: contain;
  }
