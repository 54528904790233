.boxContainer {
    width: 100%;
    margin-bottom: 30px;
    margin-top: 32px;
    display: flex;
}

.textFieldLogin {
    max-width: 432px;
}

.btnLoginContainer {
    display: flex !important; 
    max-width: 480px !important;
}

.addTablesContainer {
    margin-bottom: 10px;
    max-width: 480px !important;
}

.btnAddTables {
    width: 87px !important;
    height: 56px;
    margin-right: -15px !important;
}

.btnExportContainer {
    max-width: 480px !important;
    margin-top: 14px;
}

.btnExportTypo {
    display: flex;
    padding-top: 10px;
}