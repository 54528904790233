.dataCardContainer {
  background-color: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  margin: 8px;
  display: flex;
  height: 88px;
}
.cardContentTitle {
  width: -webkit-fill-available;
}
.cardContainer {
  padding-top: 120px;
  padding-bottom: 40px;
  width: 420px;
}
.moreIcon{
   border-radius: 0px !important;
   height: 26px;
   width: 26px;
}
.loader {
  background: url('../../assets/loader.gif') no-repeat center center;
  position: absolute;
  top: calc(50vh - 25px);
  left: calc(50vw - 25px);
  height: 50px;
  width: 50px;
  z-index: 1;
  background-size: contain;
}
.loaderGif {
  background: url('../../assets/loader.gif') no-repeat center center;
  position: absolute;
  height: 50px;
  width: 50px;
  z-index: 1;
  background-size: contain;
}
.processingLoader {
  background: url('../../assets/processing-loader.gif') no-repeat center center;
  height: 24px;
  width: 24px;
  z-index: 1;
  background-size: contain;
  margin: 56px auto 24px auto;
  display: block;
}
