.MuiDropzoneArea-text {
    font-size: 1px
}
.uploadArea{
  font-size: 1px
}
.MuiDropzoneArea-root {
    height: 100%;
}
.boxContainer{
  max-width: 440px;
display: block;
margin-left: auto;
margin-right: auto;
}
.chipsStyles {
  background-color: #ffffff !important;
  border: none !important;
  font-size: 14px !important;
  color: #546E7A !important;
  min-height: 32px !important;
}
.dropzoneParagraphClass{
  color: #546E7A !important;
  font-size: 14px !important;
  letter-spacing: 0.1px !important;
  line-height: 20px !important;
  text-align: center !important;
  font-weight: 400 !important;
}
.previewImg{
  text-align: start !important;
  background-color: red
}
.dropzoneClass{
  border: 1px dashed rgba(0, 0, 0, 0.23) !important;
  margin-bottom: 16px;
}
.previewGridContainer{
  display: block !important;
}
