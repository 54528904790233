::-webkit-scrollbar {
  width: 0.4em;
  height: 0.4em;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}
::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
}

.loader {
    background: url('../../assets/loader.gif') no-repeat center center;
    position: absolute;
    top: calc(50vh - 25px);
    left: calc(50vw - 25px);
    height: 50px;
    width: 50px;
    z-index: 1;
    background-size: contain;
  }

  .low {
    color: #d32f2f !important;
    background-color: #feebee;
    line-height: 17px;
    text-align: center !important;
  }
  .medium {
    color: #f67c01 !important;
    background-color: #fff3e0;
    line-height: 17px;
    text-align: center !important;
  }
  .high {
    color: #09bc71 !important;
    background-color: #e7f8f1;
    line-height: 17px;
    text-align: center !important;
  }

  .dataSimTable {
    max-height: calc(100vh - 260px);
    overflow-y: auto;
  }
  .dataSimTable::-webkit-scrollbar-track {
    margin-top: 60px;
  }